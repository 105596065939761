import * as Actions from "../actions"

const intialState={
    responseMessage:"",
    transactionId:"",
    isLoaderActive: true,
}

const LandingReducers=(state=intialState,action)=>{
   switch(action.type){
        case Actions.GET_RESPONSE_FROM_ZENDESK:
            return{
                ...state,
                authCode:action.payload
            }
        case Actions.SET_RESPONSE_MESSAGE:
            return{
                ...state,
                responseMessage:action.payload
            }
        
        case Actions.SET_TRANSANCTION_ID:
            return{
                ...state,
                transactionId:action.payload
            }
        case Actions.SET_LOADER_STATE:
            return{
                ...state,
                isLoaderActive: action.payload
            }
        
        default:return state   
   }
}


export default LandingReducers