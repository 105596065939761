import axios from "axios"
export const GET_RESPONSE_FROM_ZENDESK = "GET_RESPONSE_FROM_ZENDESK"
export const SET_RESPONSE_MESSAGE = "SET_RESPONSE_MESSAGE"
export const SET_LOADER_STATE = "SET_LOADER_STATE"
export const SET_TRANSANCTION_ID = "SET_TRANSACTION_ID"


export const setOrgZendeskUniqueId = (value) => {
    return dispatch => dispatch({
        type: GET_RESPONSE_FROM_ZENDESK,
        payload: value
    })
}

export const setResponseMessage = (value) => {
    return dispatch => dispatch({
        type: SET_RESPONSE_MESSAGE,
        payload: value
    })
}

export const changeLoaderState = (value) => {
    return dispatch => dispatch({
        type: SET_LOADER_STATE,
        payload: value
    })
}

export const setTransactionId = (value) => {
    return dispatch => dispatch({
        type: SET_TRANSANCTION_ID,
        payload: value
    })
}

export const initiateOnboarding = (transactionId, subdomain, token, ticketingSystem) => {
    // only for place holder
    return async dispatch => {
        try {
            axios({
                method: "POST",
                url: `https://raa-onboarding-api.readyly.app/initiate`,
                data: {
                    "transactionId": transactionId,
                    "subdomain": subdomain,
                    "accessToken": token,
                    "ticketingSystem": ticketingSystem
                }
            }).then((response) => {
                if(response.status===200){
                    dispatch({
                        type: SET_TRANSANCTION_ID,
                        payload: transactionId
                    });
                }
                dispatch({
                    type: GET_RESPONSE_FROM_ZENDESK,
                    payload: response.data ? response.data.result : null
                })
            })
                .catch(ex => {
                    dispatch({
                        type: SET_RESPONSE_MESSAGE,
                        payload: "Oops. Something went wrong. Please notify us at support@readyly.com so we can get you up and running quickly!"
                    })
                    dispatch({
                        type: SET_LOADER_STATE,
                        payload: false
                    });
                })
        } catch (ex) {
            console.log("Oops Something went wrong", ex.message)
            dispatch({
                type: SET_RESPONSE_MESSAGE,
                payload: "Oops. Something went wrong. Please notify us at support@readyly.com so we can get you up and running quickly!"
            })
            dispatch({
                type: SET_LOADER_STATE,
                payload: false
            });
        }
    }
}

export const getProgressStatus = (transactionId, setProgressBarStatus, setProgress) => {
    return async dispatch => {
        try {
            const response = await axios({
                method: "GET",
                url: `https://raa-onboarding-api.readyly.app/status?transactionId=${transactionId}`
            })
            if(response.status === 200) {
                setProgress(Number(response.data.step)*10||0)
                setProgressBarStatus(response.data)
            }
        } catch (error) {
            console.log("Ops Something went wrong", error.message)
            dispatch({
                type: SET_RESPONSE_MESSAGE,
                payload: "Oops. Something went wrong. Please notify us at support@readyly.com so we can get you up and running quickly!"
            })
            dispatch({
                type: SET_LOADER_STATE,
                payload: false
            });
        }
    }
}

