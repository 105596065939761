import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography, LinearProgress } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "../../stores/actions";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const ProgressBar = () => {
  const [progress, setProgress] = useState(0);
  const [progressBarStatus, setProgressBarStatus] = useState({});
  const dispatch = useDispatch();

  const transactionId = useSelector(
    (state) => state.LandingReducers.transactionId
  );

  useEffect(() => {
    if (transactionId.length > 5) {
      var progressInterval = setInterval(() => {
        dispatch(
          Actions.getProgressStatus(
            transactionId,
            setProgressBarStatus,
            setProgress
          )
        );
      }, 1500);
      return () => clearInterval(progressInterval);
    }
  }, [progress, transactionId]);

  useEffect(() => {
    if (progressBarStatus?.status === "failed") {
      dispatch(
        Actions.setResponseMessage(progressBarStatus?.state?.split(":")[2])
      );
      dispatch(Actions.changeLoaderState(false));
    }

    if (progressBarStatus?.status === "completed") {
      dispatch(
        Actions.setResponseMessage(
          "Thank you for choosing Readyly! Please refresh your app to continue."
        )
      );
      dispatch(Actions.changeLoaderState(false));
    }
  }, [progressBarStatus]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "70%" }}>
        <LinearProgressWithLabel
          value={typeof progress === "number" ? progress : 0}
          style={{ height: "12px", borderRadius: "8px" }}
        />
        <p style={{ fontSize: "12px", textAlign: "center" }}>
          {progressBarStatus?.state || "Provisioning..."}
        </p>
      </Box>
    </div>
  );
};

export default ProgressBar;
