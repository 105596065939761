import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "../../stores/actions";
import { Typography } from "@mui/material";
import ProgressBar from "./progressBar";

const LandingApp = (props) => {
  const dispatch = useDispatch();

  const responseMessage = useSelector(
    (state) => state.LandingReducers.responseMessage
  );
  const isLoaderActive = useSelector(
    (state) => state.LandingReducers.isLoaderActive
  );

  useEffect(() => {
    if (window.location) {
      dispatch(Actions.changeLoaderState(true));
      
      // Extracting domain from the ancestor origin
      let domain = window.location.ancestorOrigins[0] || '';
      
      // Nothing in ancestor origin, look for referrer
      if (domain === ''){
        domain = document.referrer;
      }
      
      let ele = domain.includes('//') ? domain.split('//')[1] : '';
      let ticketingSystem = '';
      let transactionId = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
      let subdomain = ele.includes('.') ? ele.split('.')[0] : '';
      const searchParams = new URLSearchParams(window.location.search);
      if (ele.includes('.salesforce.com')) {
        // Handle Salesforce logic
        ticketingSystem = 'salesforce';
        let params = new URLSearchParams(window.location.search);
        let code = params.get('code');
        dispatch(Actions.initiateOnboarding(transactionId, subdomain, code, ticketingSystem));
      } else if (ele.includes('zendesk')) {
        // Handle Zendesk logic
        ticketingSystem = 'zendesk';
        let hash = window.location.hash.includes('=') ? window.location.hash.split('=')[1] : '';
        let token = hash.includes('&') ? hash.split('&')[0] : '';
        dispatch(Actions.initiateOnboarding(transactionId, subdomain, token, ticketingSystem));
      }
      else if (searchParams.get('subDomain').includes('.freshdesk.com')){
        subdomain = searchParams.get('subDomain').split('.freshdesk.com')[0]
        let apiKey = searchParams.get('apiKey');
        ticketingSystem = 'freshdesk';
        dispatch(Actions.initiateOnboarding(transactionId, subdomain, apiKey, ticketingSystem));
      }
      else if (searchParams.get('subDomain').includes('.gorgias.com')){
        subdomain = searchParams.get('subDomain').split('.gorgias.com')[0]
        let apiKey = searchParams.get('apiKey');
        ticketingSystem = 'gorgias';
        dispatch(Actions.initiateOnboarding(transactionId, subdomain, apiKey, ticketingSystem));
      }
    }
  }, [dispatch]);


  return (
    <div className="App">
      <div className="App-header">
        <img className="App-logo" src={"images/readyly_logo.png"} alt="logo" />
      </div>
      {isLoaderActive ? (
        <div style={{ width: "400px", margin: "auto" }}>
          {" "}
          <ProgressBar />{" "}
        </div>
      ) : (
        <div className="App-loader">
          <Typography
            style={{
              color: "#152938",
              fontSize: 20,
              fontWeight: 500,
              marginTop: 20,
              fontFamily: "sans-serif",
            }}
          >
            {responseMessage}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default LandingApp;
